import { ISerie } from "@/types/api.type";
import axios from "axios";

export const fetchPartners = async (): Promise<Record<string, any>[]> => {
  const resp = await axios.get(
    `${process.env.VUE_APP_BACKEND_URL}/api/partners?populate=image`
  );
  if (resp.status !== 200) return [];
  return resp.data.data;
};

export const fetchSeries = async (): Promise<ISerie[]> => {
  const resp = await axios.get(
    `${process.env.VUE_APP_BACKEND_URL}/api/series/?populate=deep,3`
  );
  return resp.data.data;
};

export const fetchSerie = async (id: number): Promise<ISerie> => {
  const resp = await axios.get(
    `${process.env.VUE_APP_BACKEND_URL}/api/series/${id}?populate=deep,3`
  );
  return resp.data.data;
};

export const getImageUrl = (object: any) => {
  const url = object?.attributes?.url || undefined;
  if (!url) return;
  return `${process.env.VUE_APP_BACKEND_URL}${url}`;
};
